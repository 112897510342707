import React, { useEffect } from 'react';
import Layout from '../../components/Layout';
import Seo from '../../components/Seo';
import { Container, Row, Col } from 'react-bootstrap';
import ShinyButton from '../../components/ShinyButton';
import './style.scss';
import WistiaPopoverVideo from '../../components/WistiaPopoverVideo';
import PricingPartners from '../../components/PricingPartners';
import { throwSignupConversionEvent } from '../../scripts/utils';

const Pricing = () => {
    useEffect(() => {});

    return (
        <Layout>
            <Seo title="Pricing" />
            <div className="new-pricing">
                <Container className="container-1 ">
                    <Row>
                        <Col className="centered-text">
                            <h1>
                                Save Hundreds of Dollars by Switching to One
                                WorkOS
                            </h1>
                            <p className="hero-subtext">
                                Replace the tools that weren’t made for the IT
                                industry with the one WorkOS that was.
                            </p>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-2">
                    <Row>
                        <Col className="comparison-card-col">
                            <video
                                src={require('../../videos/logo-pricing-animated.mp4')}
                                muted
                                playsInline
                                autoPlay
                                loop
                                className="glasshive-logo "
                                id="animated-glasshive-logo"
                            >
                                <p className="wrapLineSib">
                                    If you are reading this, it is because your
                                    browser does not support the HTML5 video
                                    element.
                                </p>
                            </video>
                            <div className="comparison-card">
                                <p>
                                    Reduce the stress of managing and using
                                    multiple sales and marketing tools that
                                    weren’t made for the IT industry. Replace
                                    them all with the one tool that not only
                                    consolidates them into one workOS but was
                                    created by a team with multiple years of
                                    channel experience. As for the tools you
                                    can’t operate your business without (say
                                    your PSA)…those are beautifully integrated
                                    into the Hive for a seamless experience.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-3">
                    <Row>
                        <Col>
                            <div className="subscription-card blue-blob">
                                <div className="header">
                                    <h3>
                                        Get GlassHive for $100 for the 1st user
                                        and $49 for every additional user added
                                    </h3>
                                </div>
                                <div className="content">
                                    <div className="features">
                                        <ul>
                                            <li>CRM</li>
                                            <li>Email Marketing</li>
                                            <li>Sales Management</li>
                                            <li>Tasking</li>
                                            <li>
                                                Magic Content Library
                                                <span className="fine-print">
                                                    (1000+ IT specific marketing
                                                    content & designs)
                                                </span>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li>Pre-built marketing plans</li>
                                            <li>Unlimited Contacts</li>
                                            <li>Marketing and sales reports</li>
                                            <li>
                                                Integrations to your PSAs and
                                                more{' '}
                                                <span className="fine-print">
                                                    (ConnectWise, AutoTask,
                                                    Salesforce)
                                                </span>
                                            </li>
                                            <li>
                                                Landing Page, Collateral and
                                                Email Builders
                                            </li>
                                        </ul>
                                        <ul className="opaque-check">
                                            <li>
                                                Journeys{' '}
                                                <span className="fine-print">
                                                    (Automations)
                                                </span>
                                            </li>

                                            <li>SMS Marketing</li>
                                            <li>
                                                Social Media Marketing and
                                                Management
                                            </li>
                                            <li>Document Builder </li>
                                            <li>The Studio </li>
                                            <li>
                                                Calling{' '}
                                                <span className="accent">
                                                    *
                                                </span>
                                            </li>
                                            <li className="disclaimer">
                                                * = Coming soon
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="price-container">
                                        <div className="flex justify-center hero-btns margin-top-50">
                                            <ShinyButton
                                                className="btn pink create-free-account-button"
                                                onClick={() =>
                                                    throwSignupConversionEvent(
                                                        'https://app.glasshive.com/Account/Login?s=signup'
                                                    )
                                                }
                                            >
                                                Try it free for 14 days
                                            </ShinyButton>
                                            <WistiaPopoverVideo
                                                id={'ugvjj33wg5'}
                                            >
                                                <button className="btn pink margin-left-25">
                                                    <span className="btn__mask"></span>
                                                    <span className="btn__text ">
                                                        Watch Demo
                                                    </span>
                                                </button>
                                            </WistiaPopoverVideo>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container className="container-4">
                    <Row>
                        <Col>
                            <PricingPartners />
                        </Col>
                    </Row>
                </Container>
            </div>
        </Layout>
    );
};

export default Pricing;

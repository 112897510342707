import './style.scss';
import React, { useEffect } from 'react';
import { Waypoint } from 'react-waypoint';

const PricingPartners = () => {
    useEffect(() => {});

    return (
        <div className="pricing-partner">
            <h3 className=" margin-auto">
                Over 14,000 users have joined the Hive. GlassHive will change
                how you feel about sales and marketing. The buzz is real.
            </h3>
            <div className="partners">
                <Waypoint
                    onEnter={() => {
                        document
                            .querySelector('.partners')
                            .classList.add('animate');
                    }}
                />
                <ul className="partners">
                    <li>
                        <img
                            className="first img-1"
                            src={require('../../images/logos/ingram-dark.png')}
                            alt="ingram logo"
                        />
                    </li>
                    <li>
                        <img
                            className="second img-2"
                            src={require('../../images/logos/trend-micro-dark.png')}
                            alt="trend micro logo"
                        />
                    </li>
                    <li>
                        <img
                            className="third img-3"
                            src={require('../../images/logos/apple-dark.png')}
                            alt="apple logo"
                        />
                    </li>
                    <li>
                        <img
                            className="fourth img-4"
                            src={require('../../images/logos/microsoft-dark.png')}
                            alt="microsoft logo"
                        />
                    </li>
                    <li>
                        <img
                            className="fifth img-5"
                            src={require('../../images/logos/cisco-dark.png')}
                            alt="cisco logo"
                        />
                    </li>
                    <li>
                        <img
                            className="sixth img-6"
                            src={require('../../images/logos/citrix-dark.png')}
                            alt="citrix logo"
                        />
                    </li>
                    <li>
                        <img
                            className="seventh img-7"
                            src={require('../../images/logos/f5.png')}
                            alt="f5 logo"
                        />
                    </li>
                    <li>
                        <img
                            className="eigth img-8"
                            src={require('../../images/logos/ibm-dark.png')}
                            alt="ibm logo"
                        />
                    </li>
                    <li>
                        <img
                            className="nineth img-9"
                            src={require('../../images/logos/fortinet-dark.png')}
                            alt="Fortinet Logo"
                        />
                    </li>
                    <li>
                        <img
                            className="tenth img-10"
                            src={require('../../images/home/Lenovo_logo.png')}
                            alt="lenovo logo"
                        />
                    </li>
                    <li>
                        <img
                            className="eleventh img-11"
                            src={require('../../images/logos/brother-dark.png')}
                            alt="brother logo"
                        />
                    </li>
                    <li>
                        <img
                            className="twelvth img-12"
                            src={require('../../images/logos/rsa-dark.png')}
                            alt="rsa logo"
                        />
                    </li>
                </ul>
                <div className="header-section">
                    <h4>Hear from GlassHive users share their experience:</h4>
                </div>
                <div className="testimonial-video">
                    <iframe
                        width="800"
                        height="475"
                        src="https://www.youtube-nocookie.com/embed/k2EBLLDs1fo?controls=0"
                        title="YouTube video player"
                        frameborder="2"
                        border-radius="37px"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowfullscreen
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default PricingPartners;
